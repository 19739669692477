import * as React from "react";
import TextField from "../../../../components/icarus/textfield/TextField";
import { Button, Grid } from "@mui/material";
import { PlusCircle } from "react-feather";
import BancoAutoComplete from "../../../../components/icarus/BancoAutoComplete";
import { useFormik } from "formik";
import ContratoService from "../../../../services/ContratoService";
import SelectMenu from "../../../../components/icarus/select/SelectMenu";
import { MESSAGE_VALIDATION_INPUT, TIPO_CONTA, OBSERVACAO_CONTA } from "../../../../constants";
import MaskUtil from "../../../../utils/MaskUtil";
import TextFieldNumber from "../../../../components/icarus/textfield/TextFieldNumber";
import * as Yup from "yup";
import { Close } from "@mui/icons-material";

const CadastroDadosBancarios = (props) => {
  const validationSchema = Yup.object().shape({
    jurisdicao: Yup.string().min(3).max(255),
    idContratoContea: Yup.number(),
    banco: Yup.object().required(),
    conta: Yup.string().matches(/^\d+$/).min(1).max(12).required().nullable(),
    contaDigito: Yup.string().trim().min(1).max(1).nullable(),
    agencia: Yup.string().matches(/^\d+$/).min(4).max(5).nullable().required(),
    agenciaDigito: Yup.string().transform((value) => (typeof value === 'string' ? value.trim() : value)).min(1).max(1).nullable(),
    // valorConta: Yup.string().min(2).max(255),
    tipoConta: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      jurisdicao: "Brasil",
      idContratoContea: props.idContratoContea,
      banco: null,
      conta: "",
      contaDigito: "",
      agencia: "",
      agenciaDigito: "",
      valorConta: null,
      tipoConta: null,
      observacaoConta: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      adicionarConta(values);
    },
  });

  function adicionarConta(values) {
    ContratoService.salvarConta(values).then((response) => {
      props.saveHandler(response, formik);
      formik.resetForm();
    });
  }

  React.useEffect(() => {
    if (props.idDadosBancarios) {
      ContratoService.buscarDadosBancariosPorId(props.idDadosBancarios).then(
        (response) => {
          formik.setValues(response);
          formik.setFieldValue("idContratoContea", props.idContratoContea);
        }
      );
    }
  }, [props.idDadosBancarios]);

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <BancoAutoComplete
            required
            value={formik.values.banco}
            onChange={(option, value) => formik.setFieldValue("banco", value)}
            onRemove={(option, value) => formik.setFieldValue("banco", value)}
            error={Boolean(formik.touched.banco && formik.errors.banco)}
            helperText={MESSAGE_VALIDATION_INPUT.REQUERID_FIELD}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Jurisdição"
            disabled
            value={formik.values.jurisdicao}
            error={Boolean(
              formik.touched.jurisdicao && formik.errors.jurisdicao
            )}
            helperText={formik.touched.jurisdicao && formik.errors.jurisdicao}
            onChange={(e) => formik.setFieldValue("jurisdicao", e.target.value)}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Numero da Agência"
            inputProps={{ maxLength: 5 }}
            value={formik.values.agencia}
            error={Boolean(formik.touched.agencia && formik.errors.agencia)}
            helperText={formik.touched.agencia && formik.errors.agencia}
            onChange={(e) => formik.setFieldValue("agencia", e.target.value)}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            inputProps={{ maxLength: 1 }}
            error={Boolean(
              formik.touched.agenciaDigito && formik.errors.agenciaDigito
            )}
            helperText={
              formik.touched.agenciaDigito && formik.errors.agenciaDigito
            }
            label="Digito da Agência"
            value={formik.values.agenciaDigito}
            onChange={(e) =>
              formik.setFieldValue("agenciaDigito", e?.target?.value?.toUpperCase().replace(/[^0-9X]/g, '').slice(0, 1))
            }
            fullWidth
          ></TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Numero da conta"
            inputProps={{ maxLength: 12 }}
            value={formik.values.conta}
            error={Boolean(formik.touched.conta && formik.errors.conta)}
            helperText={formik.touched.conta && formik.errors.conta}
            onChange={(e) => formik.setFieldValue("conta", e.target.value)}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            inputProps={{ maxLength: 1 }}
            label="Digito da conta"
            value={formik.values.contaDigito}
            error={Boolean(
              formik.touched.contaDigito && formik.errors.contaDigito
            )}
            helperText={formik.touched.contaDigito && formik.errors.contaDigito}
            onChange={(e) =>
              formik.setFieldValue("contaDigito", e?.target?.value?.toUpperCase().replace(/[^0-9X]/g, '').slice(0, 1))
            }
            fullWidth
          ></TextField>
        </Grid>
        {/* <Grid item md={6} sm={12} xs={12}>
          <TextFieldNumber
            label="Valor existente em conta"
            inputProps={{ maxLength: 18 }}
            value={MaskUtil.formataMoeda(formik.values.valorConta)}
            error={Boolean(
              formik.touched.valorConta && formik.errors.valorConta
            )}
            helperText={formik.touched.valorConta && formik.errors.valorConta}
            onChange={(e, value) => {
              formik.setFieldValue("valorConta", value);
            }}
            fullWidth
          ></TextFieldNumber>
        </Grid> */}
        <Grid item md={6} sm={12} xs={12}>
          <SelectMenu
            label="Tipo conta"
            id="tipoConta"
            native
            error={Boolean(formik.touched.tipoConta && formik.errors.tipoConta)}
            helperText={MESSAGE_VALIDATION_INPUT.REQUERID_FIELD}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue("tipoConta", e.target.value, true);
            }}
            value={formik.values.tipoConta}
          >
            <option aria-label="Selecione" value={undefined} />
            {TIPO_CONTA.map((item) => (
              <option
                value={item.cod}
                selected={item.cod === formik.values.tipoConta}
              >
                {item.name}
              </option>
            ))}
          </SelectMenu>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <SelectMenu
            label="Obeservação conta"
            id="observacaoConta"
            native
            error={Boolean(formik.touched.observacaoConta && formik.errors.observacaoConta)}
            helperText={MESSAGE_VALIDATION_INPUT.REQUERID_FIELD}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue("observacaoConta", e.target.value, true);
            }}
            value={formik.values.observacaoConta}
          >
            <option aria-label="Selecione" value={undefined} />
            {OBSERVACAO_CONTA.map((item) => (
              <option
                value={item.cod}
                selected={item.cod === formik.values.observacaoConta}
              >
                {item.name}
              </option>
            ))}
          </SelectMenu>
        </Grid>
        <Grid display="flex" gap={2} item md={12} sm={12} xs={12}>
          <Button type="submit" variant="contained" startIcon={<PlusCircle />}>
            {formik.values.idDadosBancarios ? "Editar" : "Adicionar Conta"}
          </Button>
          {formik.values.idDadosBancarios && (
            <Button
              variant="contained"
              onClick={() => {
                formik.resetForm();
                props.setIdDadosBancarios(null);
              }}
              startIcon={<Close />}
            >
              Cancelar
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default CadastroDadosBancarios;
