import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Alert from "../../../components/alert/Alert";
import BancoService from "../../../services/BancoService";
import Dialog from "../../../components/Dialog";
import CadastrarButton from "../../../components/icarus/buttons/CadastrarButton";
import AlterarButton from "../../../components/icarus/buttons/AlterarButton";
import CancelarButton from "../../../components/icarus/buttons/CancelarButton";
import TextField from "../../../components/icarus/textfield/TextField";
import TextFieldCNPJ from "../../../components/icarus/textfield/TextFieldCNPJ";
import MaskUtil from "../../../utils/MaskUtil";
import InformacoesFotoBanco from "./InformacoesFotoBanco";
import { Box, CircularProgress, Grid, MenuItem } from "@mui/material";
import { DOMINIO_SIM_NAO, MESSAGE_VALIDATION_INPUT } from "../../../constants";
import SelectMenu from "../../../components/icarus/select/SelectMenu";

const dominioSimNao = Object.values(DOMINIO_SIM_NAO);

const validationSchema = Yup.object().shape({
  cnpj: Yup.string()
    .max(30)
    .min(1)
    .nullable()
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  nome: Yup.string().max(30).min(1).required().nullable(),
  obrigatorioDigitoAgencia: Yup.string().max(30).min(1).required().nullable(),
  logomarca: Yup.string().nullable(),
});

function DialogBanco({ show = false, onRequestClose, data }) {
  const [showDialog, setShowDialog] = useState(show);
  const [carregando, setCarregando] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
    onRequestClose();
  };

  const createBanco = (values) => {
    const { cnpj, nome, codigo, obrigatorioDigitoAgencia } = values;
    BancoService.createBanco({
      cnpj: MaskUtil.undoMask(cnpj),
      nome,
      codigo,
      obrigatorioDigitoAgencia,
    })
      .then(() => {
        Alert.success("", "Sucesso ao incluir o Banco");
        handleClose();
      })
      .finally(() => setCarregando(false));
  };

  const updateBanco = (values) => {
    const { idBanco, cnpj, nome, codigo, obrigatorioDigitoAgencia } = values;
    BancoService.updateBanco({
      idBanco,
      cnpj,
      nome,
      codigo,
      obrigatorioDigitoAgencia,
    })
      .then(() => {
        Alert.success("", "Sucesso ao atualizar o Banco");
        handleClose();
      })
      .finally(() => setCarregando(false));
  };

  const renderCarregando = () => {
    return (
      <Box display="flex" justifyContent="center" my={6}>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <Dialog
      title={`Banco - ${!data ? "Incluir" : "Alterar"}`}
      show={showDialog}
      onRequestClose={onRequestClose}
    >
      <Formik
        initialValues={{
          idBanco: !data ? undefined : data.idBanco,
          cnpj: !data ? undefined : data.cnpj,
          nome: !data ? undefined : data.nome,
          codigo: !data ? undefined : data.codigo,
          logomarca: !data ? undefined : data.logomarca,
          obrigatorioDigitoAgencia: !data
            ? undefined
            : data.obrigatorioDigitoAgencia,
          submit: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setCarregando(true);
          if (!data) {
            createBanco(values);
          } else {
            updateBanco(values);
          }
        }}
      >
        {(formik) => (
          <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container spacing={6}>
              {formik.values.idBanco && (
                <>
                  <Grid item md={12} sm={12} xs={12}>
                    <InformacoesFotoBanco
                      idBanco={formik.values.idBanco}
                      caminhoFoto={formik.values.logomarca}
                    />
                  </Grid>
                </>
              )}

              <Grid item md={12} sm={12} xs={12}>
                <TextFieldCNPJ
                  name="cnpj"
                  label="CNPJ"
                  required
                  value={formik.values.cnpj}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.cnpj && formik.errors.cnpj)}
                  helperText={formik.touched.cnpj && formik.errors.cnpj}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue("cnpj", e, true);
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="codigo"
                  label="Código"
                  value={formik.values.codigo}
                  fullWidth
                  required
                  my={10}
                  error={Boolean(formik.touched.codigo && formik.errors.codigo)}
                  helperText={formik.touched.codigo && formik.errors.codigo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="nome"
                  label="Nome"
                  value={formik.values.nome}
                  fullWidth
                  required
                  my={10}
                  error={Boolean(formik.touched.nome && formik.errors.nome)}
                  helperText={formik.touched.nome && formik.errors.nome}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <SelectMenu
                  value={formik.values.obrigatorioDigitoAgencia}
                  label="Obrigatório o Digito da Agência"
                  error={Boolean(
                    formik.touched.obrigatorioDigitoAgencia &&
                      formik.errors.obrigatorioDigitoAgencia
                  )}
                  helperText={
                    formik.touched.obrigatorioDigitoAgencia &&
                    formik.errors.obrigatorioDigitoAgencia
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "obrigatorioDigitoAgencia",
                      e.target.value,
                      true
                    );
                  }}
                  required
                  my={10}
                >
                  {dominioSimNao.map((item) => (
                    <MenuItem value={item.name}>{item.desc}</MenuItem>
                  ))}
                </SelectMenu>
              </Grid>
            </Grid>
            <Box my={2} />
            {carregando ? (
              renderCarregando()
            ) : (
              <Grid container spacing={2}>
                <Grid item>
                  <CancelarButton onClick={handleClose} />
                </Grid>
                <Grid item>
                  {!data ? <CadastrarButton /> : <AlterarButton />}
                </Grid>
              </Grid>
            )}
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default DialogBanco;
