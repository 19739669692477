import VMasker from "vanilla-masker";
import _ from "lodash";

const CPF_MASK = "999.999.999-99";
const PIS_MASK = "999.99999.99-9";
const CNPJ_MASK = "99.999.999/9999-99";
const DATE_MASK = "99/99/9999";
const CEP_MASK = "99.999-999";
const CELLPHONE_MASK = "(99) 99999-9999";
const PHONE_MASK = "(99) 9999-9999";
const HOUR_MASK = "999:99";

function cellPhone(valor) {
  if (valor === undefined || valor === null) {
    return;
  }
  return VMasker.toPattern(valor, CELLPHONE_MASK);
}

function phone(valor) {
  if (valor === undefined || valor === null) {
    return;
  }
  return VMasker.toPattern(valor, PHONE_MASK);
}

function cep(valor) {
  if (valor === undefined || valor === null) {
    return;
  }
  return VMasker.toPattern(valor, CEP_MASK);
}

function moneySeparadorMilhar(valor) {
  if (valor === undefined || valor === null) {
    return;
  }

  return "R$ " + VMasker.toMoney(valor);
}

function formataMoeda(valor) {
  if (!valor) {
    return;
  }
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(valor);
}

function money(valor, prefix = true) {
  if (valor === undefined || valor === null) {
    return;
  }

  let str = valor.toString();
  const lengthStr = str.length;
  const posicaoPonto = str.search(/\./);

  if (posicaoPonto > 0) {
    const valueDecimal = str.substring(posicaoPonto + 1, lengthStr);
    const valorAux = str.substring(0, posicaoPonto);
    const valorDecimalAllowZero = _.padEnd(valueDecimal, 2, "0");

    str = valorAux.concat(",", valorDecimalAllowZero);
  } else {
    str = str.concat(",00");
  }

  return (prefix ? "R$ " : "").concat(str);
}

function date(valor) {
  if (valor === undefined || valor === null) {
    return "";
  }

  return VMasker.toPattern(valor, DATE_MASK);
}

function hour(valor, mask = HOUR_MASK) {
  if (valor === undefined || valor === null) {
    return "";
  }

  return VMasker.toPattern(valor, mask);
}

function cpf(valor) {
  if (valor === undefined || valor === null) {
    return "";
  }
  return VMasker.toPattern(valor, CPF_MASK);
}

function cpfCnpj(valor) {
  if (valor === undefined || valor === null) {
    return "";
  }

  if (valor.length > 11) {
    return VMasker.toPattern(valor, CNPJ_MASK);
  }

  return VMasker.toPattern(valor, CPF_MASK);
}

function pis(valor) {
  if (valor === undefined || valor === null) {
    return "";
  }
  return VMasker.toPattern(valor, PIS_MASK);
}

function cnpj(valor) {
  if (valor === undefined || valor === null) {
    return;
  }
  return VMasker.toPattern(valor, CNPJ_MASK);
}

function undoMask(valor) {
  if (valor === undefined || valor === null) {
    return;
  }
  return valor.replace(/\D/g, "");
}

export const round = (valor) => {
  return _.round(valor, 2);
};

function percent(valor, prefix = true) {
  if (valor === undefined || valor === null) {
    return;
  }
  valor = round(valor);

  let str = valor.toString();
  const lengthStr = str.length;
  const posicaoPonto = str.search(/\./);

  if (posicaoPonto > 0) {
    const valueDecimal = str.substring(posicaoPonto + 1, lengthStr);
    const valorAux = str.substring(0, posicaoPonto);
    const valorDecimalAllowZero = _.padEnd(valueDecimal, 2, "0");

    str = valorAux.concat(".", valorDecimalAllowZero);
  } else {
    str = str.concat(".00");
  }

  return str.concat(prefix ? "%" : "");
}

function onlyCharNumber(valor) {
  return VMasker.toNumber(valor);
}
const MaskUtil = {
  cellPhone,
  cep,
  cpfCnpj,
  money,
  formataMoeda,
  date,
  pis,
  cpf,
  cnpj,
  undoMask,
  phone,
  percent,
  onlyCharNumber,
  moneySeparadorMilhar,
  hour,
};
export default MaskUtil;
