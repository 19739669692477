//import axios from "../utils/axios";
import Axios from "axios";

function buscarBanco(nome, cnpj, codigo, page = 0) {
  return new Promise((resolve, reject) => {
    const vo = {
      codigo,
      nome,
      cnpj,
    };
    Axios.post(`/banco/pesquisa/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function createBanco({ cnpj, nome, codigo, obrigatorioDigitoAgencia }) {
  return new Promise((resolve, reject) => {
    const vo = {
      obrigatorioDigitoAgencia,
      cnpj,
      nome,
      codigo,
    };
    Axios.post(`/banco`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateBanco({
  idBanco,
  cnpj,
  nome,
  codigo,
  obrigatorioDigitoAgencia,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      codigo,
      obrigatorioDigitoAgencia,
      idBanco,
      cnpj,
      nome,
    };
    Axios.put(`/banco`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteBanco(idBanco) {
  return new Promise((resolve, reject) => {
    Axios.delete(`/banco/${idBanco}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarLogomarca(file, idBanco) {
  const formData = new FormData();
  formData.append("file", file);
  return Axios.put(`/banco/logomarca/${idBanco}`, formData, {
    headers: { "content-type": "multipart/form-data;" },
  });
}

function deletarLogomarca(idBanco) {
  return new Promise((resolve, reject) => {
    Axios.delete(`/banco/logomarca/${idBanco}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const BancoService = {
  buscarBanco,
  createBanco,
  updateBanco,
  deleteBanco,
  alterarLogomarca,
  deletarLogomarca,
};

export default BancoService;
