//import axios from "../utils/axios";
import Axios from "axios";

function pesquisar(page, status, cliente) {
  console.log(status, cliente)
  return new Promise((resolve, reject) => {
    Axios.post(`/contrato/pesquisa/${page}/`, {
      cliente,
      status
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function pesquisarContea(page, status, cliente) {
  console.log(status, cliente)
  return new Promise((resolve, reject) => {
    Axios.post(`/contrato/pesquisaContea/${page}/`, {
      cliente,
      status
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function salvar(data) {
  return Axios.post("/contrato", data);
}

function buscarDadosBancariosPorId(idDadosBancarios){
  return new Promise((resolve, reject) => {
    Axios.get(`/dadosBancariosContrato/buscar/${idDadosBancarios}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function enviarCompliance(idContrato){
  return Axios.post(`/contrato/enviarCompliance/${idContrato}`)
}

function aceitarContrato(idContrato){
  return Axios.post(`/contrato/aceitar/${idContrato}`)
}
function recusarContrato(idContrato){
  return Axios.post(`/contrato/recusar/${idContrato}`)
}

function salvarConta(data) {
  return Axios.post("/dadosBancariosContrato", data);
}

function buscarDadosBancariosPorContrato(page = 0, idContrato) {
  return new Promise((resolve, reject) => {
    Axios.get(`/dadosBancariosContrato/buscarPorContrato/${idContrato}/${page}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarContrato(idContrato) {
  return new Promise((resolve, reject) => {
    Axios.get(`/contrato/buscar/${idContrato}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function apagarDadoBancario(idDadosBancarios){
  return Axios.delete(`/dadosBancariosContrato/apagar/${idDadosBancarios}`);
}

async function gerarContrato(idContrato){
  return Axios.get(`/contrato/gerar/${idContrato}`, {responseType: 'blob'});
}

const ContratoService = {
  pesquisar,
  salvar,
  salvarConta,
  buscarContrato,
  apagarDadoBancario,
  buscarDadosBancariosPorContrato,
  aceitarContrato,
  recusarContrato,
  buscarDadosBancariosPorId,
  enviarCompliance,
  gerarContrato,
  pesquisarContea
};

export default ContratoService;
