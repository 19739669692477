import { Box, Button, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components/macro";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Loading from "../../../components/Loading";
import Alert from "../../../components/alert/Alert";
import BancoService from "../../../services/BancoService";
import { CloudUpload } from "@mui/icons-material";

const CenteredContent = styled.div`
  text-align: center;
`;

const InformacoesFotoBanco = (props) => {
  const { idBanco, caminhoFoto } = props;

  const [confirmDialogExcluir, setConfirmDialogExcluir] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [caminhoFotoValue, setCaminhoFotoValue] = useState(caminhoFoto);

  const uploadAvatar = (value) => {
    setCarregando(true);
    BancoService.alterarLogomarca(value.target.files[0], idBanco)
      .then((ret) => {
        setCaminhoFotoValue(ret.data);
        Alert.success("", "Logomarca Alterada Com Sucesso!");
      })
      .finally(() => setCarregando(false));
  };

  const deleteFoto = () => {
    setCarregando(true);
    BancoService.deletarLogomarca(idBanco)
      .then(() => {
        Alert.success("", "Logomarca Removida Com Sucesso!");
        setCaminhoFotoValue(null);
      })
      .finally(() => setCarregando(false));
  };

  return (
    <>
      {carregando ? (
        <Loading />
      ) : (
        <CenteredContent>
          {confirmDialogExcluir && (
            <ConfirmDialog
              title="Excluir Logomarca?"
              open={confirmDialogExcluir}
              onRequestClose={() => setConfirmDialogExcluir(false)}
              onConfirm={deleteFoto}
            >
              Tem certeza de que deseja excluir esta logomarca?
            </ConfirmDialog>
          )}

          <Box ml={1}>
            {caminhoFotoValue && (
              <img
                alt="logomarca"
                width={190}
                height={70}
                src={caminhoFotoValue}
              />
            )}
          </Box>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={(value) => uploadAvatar(value)}
          />

          {caminhoFotoValue && (
            <>
              <Link
                style={{ cursor: "pointer" }}
                onClick={() => setConfirmDialogExcluir(true)}
              >
                Remover
              </Link>
              <Box my={3} />
            </>
          )}
          <label htmlFor="raised-button-file">
            <Button variant="contained" color="primary" component="span">
              <CloudUpload mr={2} /> Upload Logomarca
            </Button>

            <Typography variant="caption" display="block" gutterBottom>
              Para obter os melhores resultados, utilize uma imagem de boa
              resolução
            </Typography>
          </label>
        </CenteredContent>
      )}
    </>
  );
};

export default InformacoesFotoBanco;
