import { Chip } from "@mui/material";
import React from "react";

const StatusContrato = ({ contrato }) => {
  const getChipColor = (status) => {
    switch (status) {
      case "PROPOSTA":
        return "#A3A3A3";
      case "COMPLIANCE":
        return "#6678FF";
      case "ACEITO":
        return "#1DD14A";
      case "RECUSADO":
        return "#FF4245";
      default:
        return "#FFFF";
    }
  };

  const getLabelText = () => {
    if (contrato.unificacao === "SIM") {
      return `${contrato.status} (Unificado)`;
    } else {
      return `${contrato.status} ${
        contrato.contratoOrigem ? "(Renovado)" : ""
      }`;
    }
  };

  return (
    <React.Fragment>
      <Chip
        size="small"
        style={{
          backgroundColor: getChipColor(contrato.status),
          color: 'white',
          fontWeight: 'bold',
        }}
        mr={1}
        mb={1}
        label={getLabelText()}
      />
    </React.Fragment>
  );
};

export default StatusContrato;
