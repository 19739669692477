import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper as MuiPaper,
  Typography,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Box, spacing } from "@mui/system";
import React from "react";
import styled from "@emotion/styled";
import { GridCloseIcon } from "@mui/x-data-grid";
import { Close } from "@mui/icons-material";

const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root2: {
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function Dialog({
  title,
  children,
  childrenButton,
  show = false,
  onRequestClose,
  maxWidth = "sm",
  fullScreen = false,
  disableEscapeKeyDown = false,
  showClose = true,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(show);

  const handleClose = () => {
    setOpen(false);
    onRequestClose();
  };

  return (
    <Paper mt={4}>
      <React.Fragment>
        <MuiDialog
          fullWidth={true}
          maxWidth={maxWidth}
          disableEscapeKeyDown={disableEscapeKeyDown}
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          disableBackdropClick
          aria-labelledby="max-width-dialog-title"
        >
          {showClose && (
            <DialogTitle id="max-width-dialog-title">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    size="large"
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
          )}
          <Box my={1} />
          <DialogContent>{children}</DialogContent>
          <DialogActions>{childrenButton}</DialogActions>
        </MuiDialog>
      </React.Fragment>
    </Paper>
  );
}

export default Dialog;
