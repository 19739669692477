import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-masked-input";
import { ImSortNumbericDesc } from "react-icons/im";

interface TextNumberCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextNumberCustom(props: TextNumberCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <CurrencyInput separator={"."} allowNegative={false} required {...props} />
  );
}

const TextFieldNumber = (props) => {
  const [isShrink, setIsShrink] = useState(false);

  useEffect(() => {
    setIsShrink(props.value);
  }, [props.value]);

  return (
    <TextField
      variant="outlined"
      InputLabelProps={{ shrink: isShrink }}
      {...props}
      InputProps={{
        inputComponent: TextNumberCustom,
        startAdornment: (
          <InputAdornment position="start">
            <ImSortNumbericDesc size={20} />
          </InputAdornment>
        ),
      }}
      onFocus={(e) => {
        setIsShrink(true);
        if (props.onFocus) {
          props.onFocus(e);
        }
      }}
      onBlur={(e) => {
        if (!props.value) {
          setIsShrink(false);
        }
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
    />
  );
};

export default TextFieldNumber;
