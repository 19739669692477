import {
  Badge,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { RiMovieLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import PermissionUtil from "../../utils/PermissionUtil";
import Dialog from "../Dialog";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const BreadcrumbsNav = ({
  linkLabel = "Início",
  label,
  children,
  video,
  children2,
}) => {
  const { user } = useSelector((state) => state.authReducer);

  const [showDialog, setShowDialog] = useState(false);

  var link = "/app";

  return (
    <>
      {/* {showDialog && (
        <Dialog
          title={`Vídeo Explicativo`}
          show={showDialog}
          onRequestClose={() => setShowDialog(false)}
        >
          <iframe
            width={isMobile ? 260 : 550}
            height="315"
            src={`${video}?autoplay=1&controls=2&fs=1&rel=0&showinfo=0&modestbranding=1`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Dialog>
      )} */}
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={link}>
          {linkLabel}
        </Link>
        {children}
        {label && <Typography>{label}</Typography>}

        {video && (
          <Badge badgeContent={"1"} color="primary">
            <RiMovieLine
              size={20}
              color={"#49bc89"}
              style={{ cursor: "pointer" }}
              onClick={() => setShowDialog(true)}
            />
          </Badge>
        )}
        {children2}
      </Breadcrumbs>
      <Box my={3} />
    </>
  );
};

export default BreadcrumbsNav;
