import React, { useState } from "react";
import DialogBanco from "../../pages/cadastro/banco/DialogBanco";
import BancoService from "../../services/BancoService";
import AutoCompleteAsyncPersist from "./autocomplete/AutoCompleteAsyncPersist";
import { Avatar, Chip } from "@mui/material";

const BancoAutoComplete = (props) => {
  const [show, setShow] = useState(false);

  const handleFetchAPI = async (valueSearch) => {
    const response = await BancoService.buscarBanco(valueSearch, null, null, 0);
    return response.content;
  };

  const handleClose = () => {
    setShow(false);
  };

  const renderTags = (tagValue, getTagProps) => {
    return tagValue.map((option, index) => (
      <Chip
        color="secondary"
        avatar={<Avatar alt={option.nome} src={option.logomarca} />}
        label={option.codigo + " - " + option.nome}
        {...getTagProps({ index })}
      />
    ));
  };

  return (
    <AutoCompleteAsyncPersist
      name="autoBanco"
      label="Banco"
      permission={["manter_bancos"]}
      itemLabel={"nomeFormatado"}
      fullWidth
      renderTags={renderTags}
      showCadastro={show}
      setShowCadastro={setShow}
      onFetchAPI={handleFetchAPI}
      {...props}
      renderPersist={(value) => {
        return (
          <DialogBanco
            show={show}
            onRequestClose={() => handleClose()}
            initValue={value}
          />
        );
      }}
    />
  );
};

export default BancoAutoComplete;
