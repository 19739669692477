import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import { darken } from "polished";
import React from "react";
import { MdPayment } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import * as types from "../constants";
import UsuarioService from "../services/UsuarioService";
import Alert from "./alert/Alert";

import EmpresaComplete from "./icarus/EmpresaComplete";
import MessagesDropdown from "./MessagesDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import UserDropdown from "./UserDropdown";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;

  display: block;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
`;

const SearchButton = styled.div`
  border-radius: 2px;

  display: none;
  position: relative;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const InputEmpresa = styled(EmpresaComplete)`
  color: inherit;
  padding: 8px;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const AppBarComponent = ({ onDrawerToggle, theme }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  const changeEmpresa = (value) => {
    UsuarioService.changeCompany(value.idEmpresa).then((response) => {
      dispatch({ type: types.CHANGE_EMPRESA, user: response });
      Alert.success("Sucesso", "Empresa alterada com sucesso");
      history.go(0);
    });
  };

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        elevation={0}
        style={{
          background: theme.header.background,
          color: theme.header.color,
        }}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xl={9} md={9}>
              <Grid container>
                {(user.profile.papel === "SUPORTE" ||
                  user.profile.papel === "RECRUTAMENTO" ||
                  (user.profile.papel === "ADMINISTRADORA" &&
                    user.companyLinked &&
                    user.companyLinked.length > 0)) && (
                  <Grid item md={4}>
                    <Search>
                      <InputEmpresa
                        value={user.company}
                        onChange={(value) => {
                          changeEmpresa(value);
                        }}
                      />
                    </Search>
                  </Grid>
                )}

                {user.company.diasFaltantesTrial !== undefined &&
                  user.company.diasFaltantesTrial !== null &&
                  (user.profile.papel === "ADMINISTRADORA" ||
                    user.profile.papel === "SUPORTE" ||
                    user.profile.papel === "RECRUTAMENTO") && (
                    <Grid item md={4} style={{ alignSelf: "center" }}>
                      <SearchButton>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ height: 53 }}
                          startIcon={<MdPayment />}
                          onClick={() => history.push("/planos")}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              fontSize: 12,
                              fontWeight: "600",
                            }}
                          >
                            {user.company.diasFaltantesTrial < 0 ? (
                              <text style={{ color: "red" }}>
                                Seu período de avaliação terminou
                              </text>
                            ) : (
                              <text>
                                Seu período de avaliação termina em{" "}
                                {user.company.diasFaltantesTrial} dias
                              </text>
                            )}
                          </div>
                        </Button>
                      </SearchButton>
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <Grid item xs />
            <Grid item>
              <MessagesDropdown />
              <NotificationsDropdown />
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
