//import axios from "../utils/axios";
import Axios from "axios";

function pesquisar(tipoArquivo, nomeDocumento, idCliente, page = 0) {
  return new Promise((resolve, reject) => {
    const vo = {
      tipoArquivo,
      nomeDocumento,
      mutuario: { idCliente },
    };

    Axios.post(`/clienteDocumento/pesquisa/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function download(idClienteDocumento) {
  return new Promise((resolve, reject) => {
    Axios.get(`/clienteDocumento/downloadFile/${idClienteDocumento}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function salva({
  disponivelParaAssinatura,
  tipoArquivo,
  nomeDocumento,
  resourceBase64,
  idCliente,
  contentType,
  extension,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      disponivelParaAssinatura,
      tipoArquivo,
      nomeDocumento,
      resourceBase64,
      cliente: { idCliente },
      contentType,
      extension,
    };

    Axios.post(`/clienteDocumento`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function excluir(id) {
  return new Promise((resolve, reject) => {
    Axios.delete(`/clienteDocumento/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function contrato(idContrato, page = 0) {
  return new Promise((resolve, reject) => {
    Axios.post(`/clienteDocumento/contrato/${page}/${idContrato}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ClienteDocumentoService = {
  pesquisar,
  download,
  salva,
  excluir,
  contrato
};

export default ClienteDocumentoService;
