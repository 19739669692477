import { Button } from "@mui/material";
import React from "react";
import { Edit } from "react-feather";

const EditarButtonTable = ({
  onClick,
  title = "Editar",
  legend = "Editar",
}) => {
  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="primary"
        title={legend}
        startIcon={<Edit />}
        onClick={() => onClick()}
      >
        {title}
      </Button>
    </>
  );
};

export default EditarButtonTable;
