import { Grid, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { withTheme } from "@mui/styles";
import { Column } from "primereact/column";
import { DataTable as PDatatable } from "primereact/datatable";
import React, { useState } from "react";
import { HeaderExporter } from "./Exporter";

const DataTable = (props) => {
  const {
    id = "tabelaPrimefaces",
    exporter = true,
    scrollable,
    objPage,
    carregando,
    renderAction,
    titleColumnAction = "Ações",
    onPage = () => {},
  } = props;

  const [refTable, setRefTable] = useState();

  const actionTemplate = (rowData, column) => {
    return (
      <Grid container spacing={2}>
        {renderAction({ rowData, column })}
      </Grid>
    );
  };

  return (
    <>
      {carregando && (
        <>
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
        </>
      )}

      {objPage
        ? !carregando && (
            <div className="datatable-responsive-demo">
              <div className="card">
                <PDatatable
                  rowGroupMode={props.rowGroupMode}
                  groupField={props.groupField}
                  sortMode={props.sortMode}
                  sortField={props.sortField}
                  sortOrder={props.sortOrder}
                  rowClassName={props.rowClassName}
                  rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
                  rowGroupFooterTemplate={props.rowGroupFooterTemplate}
                  value={objPage.content}
                  footerColumnGroup={props.footerColumnGroup}
                  paginator
                  rows={objPage.size}
                  columnResizeMode="fit"
                  resizableColumns
                  totalRecords={objPage.totalElements}
                  lazy
                  id={id}
                  tableClassName={id}
                  className="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
                  responsive
                  scrollable={scrollable}
                  scrollHeight="400px"
                  first={objPage.pageable.pageNumber * objPage.size}
                  emptyMessage="Nenhum registro encontrado para os critérios informados."
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  loading={carregando}
                  onPage={(event) => onPage(event.page)}
                  currentPageReportTemplate="{first} - {last} Total {totalRecords}"
                  ref={(el) => setRefTable(el)}
                  // header={
                  //   exporter && (
                  //     <HeaderExporter
                  //       objPage={objPage}
                  //       id={id}
                  //       refTable={refTable}
                  //       props={props}
                  //     />
                  //   )
                  // }
                >
                  {props.children}

                  {renderAction && (
                    <Column
                      body={actionTemplate}
                      style={
                        props.widthColumnAction && {
                          width: props.widthColumnAction,
                        }
                      }
                      header={titleColumnAction}
                    />
                  )}
                </PDatatable>
              </div>
            </div>
          )
        : !carregando && (
            <Typography>
              Nenhum registro encontrado para os critérios informados.
            </Typography>
          )}
    </>
  );
};

export default withTheme(DataTable);
