import React from "react";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Check, X } from "react-feather";

const ConfirmDialog = (props) => {
  const {
    title,
    children,
    open,
    onRequestClose,
    onConfirm,
    severity = "warning",
    variant = "outlined",
  } = props;
  return (
    <Dialog
      open={open}
      onClose={() => onRequestClose()}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <Alert severity={severity} variant={variant}>
          {children}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onRequestClose()}
          startIcon={<X />}
        >
          Não
        </Button>
        <Button
          startIcon={<Check />}
          color="secondary"
          variant="contained"
          onClick={() => {
            onRequestClose();
            onConfirm();
          }}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
