import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import "./mocks";
import "./scss/main.scss";

import InterceptorRequestResponse from "./utils/InterceptorRequestResponse";
import axios from "./utils/axios";

import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";

import configureStore from "./redux/store/index";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const { store, persistor } = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
