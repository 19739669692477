import { Button, CircularProgress } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { useSelector } from "react-redux";
import PermissionUtil from "../../../utils/PermissionUtil";
import StringUtil from "../../../utils/StringUtil";

const AutoCompleteAsyncPersist = (props) => {
  const { user } = useSelector((state) => state.authReducer);

  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const fetch = React.useMemo(
    () =>
      debounce((texto, callback) => {
        setLoading(true);
        props
          .onFetchAPI(texto.input)
          .then((response) => {
            return callback(response);
          })
          .finally(() => setLoading(false));
      }, 400),
    []
  );

  React.useEffect(() => {
    chamaWs(inputValue, true);
  }, [inputValue, fetch]);

  const chamaWs = (inputValue, validaQtdMinimaChar) => {
    let active = true;

    if (
      validaQtdMinimaChar &&
      (!inputValue || inputValue === "" || inputValue.legth <= 3)
    ) {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  };

  const handleOpen = () => {
    setOpen(true);
    chamaWs("", false);
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const renderOption = (props1, option) => {
    return (
      <>
        {option.inputValue ? (
          <div {...props1}>
            {PermissionUtil.hasPermissions(props.permission, user) && (
              <Button
                variant="text"
                color="primary"
                onClick={() => props.setShowCadastro(true)}
              >
                {option.title}
              </Button>
            )}
          </div>
        ) : (
          <Typography noWrap {...props1}>
            {StringUtil.fetchFromObject(option, props.itemLabel)}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <Autocomplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        open={open}
        onOpen={() => handleOpen()}
        onClose={() => handleClose()}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        getOptionLabel={(option) => {
          if (option.inputValue) {
            return option.inputValue;
          }

          return StringUtil.fetchFromObject(option, props.itemLabel);
        }}
        filterOptions={(options, params) => {
          if (inputValue !== "" && (!options || options.length === 0)) {
            options.push({
              inputValue,
              title: `Adicionar "${inputValue}"`,
            });
          }

          return options;
        }}
        options={options}
        loading={loading}
        autoComplete
        includeInputInList
        {...props}
        noOptionsText="Nenhum resultado encontrado!"
        onChange={(event, value, reason, details) => {
          if (reason === "removeOption") {
            props.onRemove(details.option, value, reason, details);
          } else if (reason === "selectOption") {
            props.onChange(details.option, value, reason, details);
          } else if (reason === "clear") {
            props.onChange(details, value, reason, details);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if (event && event.type === "change") {
            setInputValue(newInputValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            required={props.required}
            error={props.error}
            helperText={props.helperText}
            variant="outlined"
            onBlur={props.onBlur}
            style={props.style}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => renderOption(props, option)}
      />
      {props.showCadastro && props.renderPersist(inputValue)}
    </>
  );
};

AutoCompleteAsyncPersist.propTypes = {
  onFetchAPI: PropTypes.func.isRequired,
  renderPersist: PropTypes.func.isRequired,
  permission: PropTypes.array.isRequired,
};

AutoCompleteAsyncPersist.defaultProps = {
  onRemove: () => {},
  onChange: () => {},
};

export default AutoCompleteAsyncPersist;
