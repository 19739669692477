import { Button } from "@mui/material";
import React from "react";
import { Trash2 } from "react-feather";

const ExcluirButtonTable = ({
  onClick,
  title = "Excluir",
  legend = "Excluir",
}) => {
  return (
    <>
      <Button
        type="button"
        variant="outlined"
        color="secondary"
        title={legend}
        startIcon={<Trash2 />}
        onClick={() => onClick()}
      >
        {title}
      </Button>
    </>
  );
};

export default ExcluirButtonTable;
